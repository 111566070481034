import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import Img from 'gatsby-image'

const ImageBar = () => (
    <StaticQuery
    query={graphql`
        {
            background: file(relativePath: {eq: "DJI_0118.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 950, quality: 95, cropFocus: CENTER) {
                        ...GatsbyImageSharpFluid_withWebp_tracedSVG
                    }
                }
            }
        }
    `}
    render={ data => (
        <Img fluid={data.background.childImageSharp.fluid} alt="" className="imageBar" />
    )}
    />
)

export default ImageBar